import * as React from "react"
import Layout from "../components/layouts/layout"

const FaqPage = () => {
  return (
    <Layout title="Frequently Asked Questions">
    </Layout>
  )
}

export default FaqPage
